<template>
  <table-view
    :total='total'
    :query-info.sync='queryInfo'
    @reload-table='renderTable'
  >
    <!-- header -->
    <template #header>
      <view-search-form
        :queryInfo.sync='queryInfo'
        :tool-list="['keyword']"
        :show-clear-btn='false'
        style='display: inline-block;'
        @on-search='renderTable(1)' />
      <div class='header-button fr'>
        <el-button v-permission="['educationImp:edit']" type='primary' size='small' @click="showDialog()">
          添加
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column label='年级' prop='grade' />
      <el-table-column label='学校名称' prop='schName' />
      <el-table-column label='专业' prop='majorName' />
      <el-table-column label='是否启用' align="center">
        <template v-slot='{ row }'>
          <el-switch
            :disabled="!hasPermission('educationImp:edit')"
            v-model='row.startUpFlag'
            @change='switchStatus($event, row)'
            :active-value='1'
            :inactive-value='0'
            active-color='var(--color-success)'
            active-text='启用'
            inactive-text='禁用' />
        </template>
      </el-table-column>
      <el-table-column label='备注' prop='remarks' />
      <el-table-column label='操作' width='150' fixed='right'>
        <template v-slot='{ row }'>
          <el-button
            v-permission="['educationImp:edit']"
            type='primary'
            size='mini'
            @click='showDialog(row.id)'
          >编辑
          </el-button>
          <el-button
            v-permission="['educationImp:del']"
            size='mini'
            type='danger'
            @click='del(deleteSchBachelorInfo,row.id,`${row.schName}-${row.majorName}`)'
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-education ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
  </table-view>
</template>

<script>
import { getSchBachelorInfo, schBachelorInfoSave, deleteSchBachelorInfo } from '@/api/count'
import addEducation from './dialog/addEducation.vue'
import tableView from '@/vue/mixins/table-view'
import { hasPermission } from '@/utils/common-js'

export default {
  components: {
    addEducation
  },
  mixins: [tableView],
  data () {
    return {}
  },
  methods: {
    hasPermission, // 判断按钮权限
    deleteSchBachelorInfo, // 删除api
    // 获取列表
    async renderTable (pageNum) {
      await this.getTableData(getSchBachelorInfo, pageNum)
    },
    // 是否启用
    switchStatus (startUpFlag, row) {
      const status = startUpFlag
      schBachelorInfoSave({
        id: row.id,
        startUpFlag: status
      }).then((res) => {
        this.renderTable()
        if (status === 1) {
          this.$message.success('已启用')
        } else {
          this.$message.warning('已禁用')
        }
      }).catch(e => {
        this.renderTable()
      })
    }
  }
}
</script>
