<template>
  <el-dialog
    class='addEducation-dialog'
    :title='`${preTitle}专升本意向`'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='720px'
    :before-close='cancel'>
    <el-form
      ref='form'
      :model='formData'
      :rules='rules'
      label-width='80px'
    >
      <el-row>
        <el-col :span='12'>
          <el-form-item label='年级' prop='grade'>
            <el-input v-model.trim='formData.grade' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='专业' prop='majorName'>
            <el-input v-model.trim='formData.majorName' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='学校' prop='schName'>
            <el-input v-model.trim='formData.schName' />
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label='是否启用'>
            <el-switch
              v-model='formData.startUpFlag'
              :active-value='1'
              :inactive-value='0'
              active-color='var(--color-success)'
              active-text='启用'
              inactive-text='禁用' />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label='备注' prop='remarks'>
        <el-input v-model.trim='formData.remarks' rows='2' type='textarea' />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button @click="save('专升本意向',schBachelorInfoSave)" type='primary'>确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getSchBachelorInfoById, schBachelorInfoSave } from '@/api/count'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        grade: '', // 年级
        majorName: '', // 专业
        schName: '', // 学校
        startUpFlag: 1, // 是否启用
        remarks: '' // 备注
      },
      rules: {
        grade: [
          { required: true, message: '请输入年级', trigger: 'blur' },
          { required: true, min: 4, max: 4, message: '仅支持4位数字', trigger: 'blur' },
          this.$store.state.commonValidate.validatorNumberObj
        ],
        schName: [
          { required: true, message: '请输入学校', trigger: 'blur' },
          this.$store.state.commonValidate.limit20WordsObj
        ],
        majorName: [
          { required: true, message: '请输入专业', trigger: 'blur' },
          this.$store.state.commonValidate.limit20WordsObj
        ]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getSchBachelorInfoById)
      }
    })
  },
  methods: {
    schBachelorInfoSave // 保存api
  }
}
</script>
